<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Room";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2d2d2d;
}

a {
  text-decoration: none !important;
  color: #ffffff;
}

p {
  margin: 0 !important;
}

.swal2-title {
  font-family: Gilroy;
  font-size: 1.5em; /* Ajusta el tamaño del título */
  color: #333; /* Cambia el color del texto */
}

.swal2-html-container {
  font-family: Gilroy;
  font-size: 1em;
  color: #666;
}

.swal2-confirm,
.swal2-cancel {
  font-family: Gilroy;
  font-size: 1em;
  color: #fff;
  background: #01354a;
}
@font-face {
  font-family: "Room";
  src: url("@/assets/Fonts/Room/Room-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Room";
  src: url("@/assets/Fonts/Room/Room-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Room";
  src: url("@/assets/Fonts/Room/Room.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Room";
  src: url("@/assets/Fonts/Room/Room-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Room";
  src: url("@/assets/Fonts/Room/Room-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

/* 2. Fuente Red Hat Display (regular, bold, italic, etc.) */
@font-face {
  font-family: "Red Hat Display";
  src: url("@/assets/Fonts/red-hat-display/RedHatDisplay-Light.ttf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Red Hat Display";
  src: url("@/assets/Fonts/red-hat-display/RedHatDisplay-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Red Hat Display";
  src: url("@/assets/Fonts/red-hat-display/RedHatDisplay-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Red Hat Display";
  src: url("@/assets/Fonts/red-hat-display/RedHatDisplay-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Red Hat Display";
  src: url("@/assets/Fonts/red-hat-display/RedHatDisplay-Black.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* 3. Fuente Gilroy (regular, medium, bold, etc.) */
@font-face {
  font-family: "Gilroy";
  src: url("@/assets/Fonts/gilroy/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("@/assets/Fonts/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("@/assets/Fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("@/assets/Fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Aplicación de las fuentes en tu proyecto */
body {
  font-family: "Room", "Red Hat Display", "Gilroy", sans-serif;
}
</style>
