import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
const isTokenValid = () => {
  const token = Cookies.get("token");
  if (!token) return false;

  try {
    const { exp } = JSON.parse(atob(token.split(".")[1])); // Decodifica el payload del JWT
    return Date.now() < exp * 1000; // Compara con la hora actual
  } catch (error) {
    return false; // Si no se puede decodificar, el token es inválido
  }
};

// Guardia de autenticación reutilizable
const authGuard = (to, from, next) => {
  if (isTokenValid()) {
    next();
  } else {
    next({
      path: "/iniciar-sesion",
      query: { redirect: to.fullPath },
    });
  }
};
const routes = [
  {
    path: "/",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
  },
  {
    path: "/remate/lotes/:id",
    name: "lotes",
    component: () => import("../views/Lotes.vue"),
  },
  {
    path: "/quienes-somos",
    name: "QuienesSomos",
    component: () => import("../views/QuienesSomos.vue"),
  },
  {
    path: "/iniciar-sesion",
    name: "IniciarSesion",
    component: () => import("../views/IniciarSesion.vue"),
    children: [
      {
        path: "",
        name: "FormIniciarSesion",
        component: () => import("../components/FormIniciarSesion.vue"),
      },
      {
        path: "cambiar-contraseña",
        name: "CambiarContraseña",
        component: () => import("../components/CambiarContraseña.vue"),
        alias: "/cambiar-contraseña",
      },
      {
        path: "menu",
        name: "MenuAdmin",
        component: () => import("../components/MenuAdmin.vue"),
        alias: "/admin/menu",
        beforeEnter: authGuard,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: "/admin/proximos-remates",
    component: () => import("../views/Admin.vue"),
    beforeEnter: authGuard,
    children: [
      {
        path: "proximos-remates",
        name: "ProximosRemates",
        props: { tipo: "proximos" },
        component: () => import("../components/Remates.vue"),
      },
      {
        path: "remates-realizados",
        name: "RematesRealizados",
        props: { tipo: "realizados" },
        component: () => import("../components/Remates.vue"),
      },
      {
        path: "cargar-remate",
        name: "CargarRemate",
        component: () => import("../components/CargarRemate.vue"),
      },
      {
        path: "panel-de-control",
        name: "PanelDeControl",
        component: () => import("../components/PanelDeControl.vue"),
      },
      {
        path: "ordenar-lotes/:id",
        name: "OrdenarLotes",
        component: () => import("../components/OrdenarLotes.vue"),
      },
      {
        path: "visualizar-remate/:id",
        name: "VisualizarRemate",
        component: () => import("../components/VisualizarRemate.vue"),
      },
      {
        path: "nuevo-lote/:id?",
        name: "NuevoLote",
        component: () => import("../components/NuevoLote.vue"),
      },
    ],
    
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          resolve();
        }, 400);
      });
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 400);
      });
    }
  },
});


export default router;
